import React from 'react'
import { useDispatch } from 'react-redux'
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react'
import { toggleFilter } from 'src/redux/sell-offer/actions'
import {
  FiltersListItemsWrapper,
  FilterTag,
  FilterTagImage,
  FilterTagCheckbox,
} from '.'

const FiltersListItemContent = ({ filter }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const dispatch = useDispatch()

  const toggleFilterHandler = (filter, groupKey) => dispatch(toggleFilter(filter.value, groupKey))

  return <AccordionItem
    borderBottomWidth={{ base: '0 !important' }}
    borderTopWidth={{ base: '0', md: '1px' }}
  >
    <AccordionButton paddingInlineStart="0" paddingInlineEnd="0" py={4} _hover={{ background: 'none' }}>
      <Box
        flex="1"
        textAlign="left"
        textTransform="uppercase"
        fontFamily="Montserrat-SemiBold"
      >
        {filter.label}
      </Box>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel paddingInlineStart="0" paddingInlineEnd="0">
      <FiltersListItemsWrapper items={filter.items} isMobile={isMobile} listedRows={filter.type === 'check'}>
        {filter.type === 'icon' ?
          filter.items.map((item: Filter.TagImage) =>
            <FilterTagImage
              isMobile={isMobile}
              item={item}
              groupKey={filter.key}
              key={`filters-brands-${item.name}-${item.value}`}
              onClick={() => toggleFilterHandler(item, filter.key)}
            />
          )
          :
          (filter.type === 'check' && !isMobile ?
            filter.items.map(item =>
              <FilterTagCheckbox
                item={item}
                groupKey={filter.key}
                key={`filters-${item.name}-${item.value}`}
                onClick={() => toggleFilterHandler(item, filter.key)}
              />
            )
            : filter.items.map(item =>
              <FilterTag
                item={item}
                groupKey={filter.key}
                key={`filters-${item.name}-${item.value}`}
                onClick={() => toggleFilterHandler(item, filter.key)}
              />
            ))}
      </FiltersListItemsWrapper>
    </AccordionPanel>
  </AccordionItem>
}

export default FiltersListItemContent
