import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { FilterTag as CommonFilterTag } from 'src/components/common'
import { filterIsSelected, filterIsHidden } from 'src/redux/sell-offer/selectors'

interface FilterProps {
  item: Filter.Tag
  groupKey: string
  onClick?(): any
  hasCloseButton?: boolean
}

const FilterTag: FC<FilterProps> = ({ item, groupKey, hasCloseButton, onClick }) => {
  const isHidden = useSelector(filterIsHidden(item, groupKey))

  if (isHidden) {
    return null
  }

  const isSelected = useSelector(filterIsSelected(item, groupKey))
  const label = hasCloseButton && item.label && item.label !== '' ? item.label : item.name

  return <CommonFilterTag
    isSelected={isSelected}
    onClick={onClick}
    hasCloseButton={hasCloseButton}
    label={label}
  />
}

export default FilterTag
