import React, { FC, useState } from 'react'
import Image from 'next/image'
import { Box, Text, Stack, Divider } from '@chakra-ui/react'
import { LimitedOfferBadge, InfoBadge, PriceBox, OfferCardTitle } from 'src/uikit'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import {getDocument} from "ssr-window";
import OfferDetails from './OfferDetails'

interface Props {
  item: OfferGlobal.SellOfferItem
}

const PriceDiscount: FC<{ name: string; value: string | null }> = ({ name, value }) => (
  <Box mt="1.3rem">
    <Text
      color="baseNeutral.700"
      fontFamily="Montserrat-Medium"
      fontSize="0.75rem"
      letterSpacing="0.4px"
      display="inline"
    >
      {`${name} `}
    </Text>
    {value &&
      <Text
        color="baseNeutral.700"
        fontFamily="Montserrat-Medium"
        fontSize="1rem"
        letterSpacing="0.2px"
        display="inline"
        textDecoration="line-through"
      >
        {value}
      </Text>
    }
  </Box>
)

const BoxOverlayOpcaity = styled(Box) <{ $active: boolean }>`
  height: 12.5rem;
  margin-bottom: 0.5rem;
  position: relative;
  background-color: ${props => props.$active ? 'baseNeutral.800' : 'transparent'};
  &:after {
    content: " ";
    z-index: 0;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(70, 75, 82, ${props => props.$active ? '0.5' : '0'});
  }
`

const Offer: FC<Props> = ({ item }) => {
  const { t } = useTranslation()
  const [active, onClick] = useState(false)
  const router = useRouter()

  const showDetailsHandler = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onClick(!active);
  }

  const getUrlWithReference = (url: string) => {
    let destinationUrl = new URL(url);
    let currentDocumentUrl = new URLSearchParams(getDocument().location.search);
    let ref = currentDocumentUrl.get("ref");
    if (ref) {
      let {searchParams} = destinationUrl;
      searchParams.set('ref', ref);
      destinationUrl.search = searchParams.toString();
    }
    return destinationUrl.toString()
  }

  const gotoDetailsHandler = () => {
    router.push(getUrlWithReference(item.code))
  }

  const title = `${item.brand} ${item.model}`
  const imageAlt = `${t('offerContainer:imageAltSuffix')} ${item.brand} ${item.model}`

  const priceValue = t('nltOffersComponent:priceValue', {
    value: parseInt(`${item.price}`).toLocaleString('it-IT'),
  })

  const specialPriceValue = item.specialPrice && item.specialPrice > 0 ?
    t('nltOffersComponent:priceValue', {
      value: parseInt(`${item.specialPrice}`).toLocaleString('it-IT'),
    })
    : null

  const monthlyRateValue = item.monthlyRate && item.monthlyRate > 0 ?
    t('sellOffersComponent:monthlyRateValue', {
      value: parseInt(`${item.monthlyRate}`).toLocaleString('it-IT'),
    })
    : null

  const offerDetailsItems = [
    {
      name: t('sellOffersComponent:depositTitle'),
      value: t('sellOffersComponent:depositValue', { value: item.deposit.toLocaleString('it-IT') }),
    },
  ]

  if (monthlyRateValue) {
    offerDetailsItems.unshift({
      name: t('sellOffersComponent:monthlyRateTitle'),
      value: monthlyRateValue,
    })
  }

  if (item.tan > 0) {
    offerDetailsItems.push({
      name: t('sellOffersComponent:tanTitle'),
      value: t('sellOffersComponent:tanValue', { value: item.tan ? item.tan.toLocaleString('it-IT') : 0 }),
    })
  }

  if (item.taeg > 0) {
    offerDetailsItems.push({
      name: t('sellOffersComponent:taegTitle'),
      value: t('sellOffersComponent:taegValue', { value: item.taeg ? item.taeg.toLocaleString('it-IT') : 0 }),
    })
  }
  let limitedOfferBadge = null

  if (item.expiresAt) {
    const offerTimeStamp = Date.parse(item.expiresAt)
    const nowTimeStamp = (new Date()).getTime()
    const diffTimeStamp = offerTimeStamp - nowTimeStamp

    if (diffTimeStamp > 0) {
      limitedOfferBadge = t('nltOffersComponent:limitedOfferBadge', {
        value: Math.floor((diffTimeStamp) / (24 * 60 * 60 * 1000))
      })
    }
  }

  return (
    <Box
      w="full"
      title={title}
      borderRadius={10}
      bg="baseWhite"
      overflow="hidden"
      borderColor={active ? 'primaryGreen.600' : '#d9dde1'}
      borderWidth="1px"
      boxShadow="0 2px 8px 0px rgba(65, 164, 118, 0.12)"
      _hover={{
        cursor: 'pointer',
        textDecoration: 'none',
        boxShadow: '0 4px 12px 0px rgba(65, 164, 118, 0.22)',
      }}
      onClick={gotoDetailsHandler}
    >
      <BoxOverlayOpcaity $active={active}>
        <Image src={item.image} alt={imageAlt} layout="fill" objectFit="contain" loading="lazy" />
        {limitedOfferBadge &&
          <LimitedOfferBadge position="absolute" top="1rem" left="1rem">
            {limitedOfferBadge}
          </LimitedOfferBadge>
        }
        {item.mainTag &&
          <InfoBadge position="absolute" bottom=".625rem" left="1rem">
            {item.mainTag}
          </InfoBadge>
        }
        <Divider color="red" position="absolute" bottom="0rem" />
      </BoxOverlayOpcaity>
      <Stack textAlign="left" pos="relative" height="7rem">
        {!active && (
          <Box px="l" color="baseDark">
            <a href={getUrlWithReference(item.code)}>
              <OfferCardTitle title={title} subtitle={item.version} />
            </a>
            <Box>
              {monthlyRateValue ?
                <PriceBox
                  value={monthlyRateValue}
                  description={t('sellOffersComponent:priceDescriptionMonthly')}
                  textAlign="right"
                  position="absolute"
                  top="3.8rem"
                  right="1.5rem"
                />
                :
                <PriceBox
                  value={specialPriceValue || priceValue}
                  description={t('sellOffersComponent:priceDescription')}
                  textAlign="right"
                  position="absolute"
                  top="3.8rem"
                  right="1.5rem"
                />
              }
              {specialPriceValue &&
                <PriceDiscount name={t('sellOffersComponent:priceStartFrom')} value={specialPriceValue ? priceValue : null} />
              }
            </Box>
          </Box>
        )}
        {active && <OfferDetails items={offerDetailsItems} description={item.description} />}
      </Stack>
      <Text
        color="baseDark"
        fontFamily="Montserrat-Medium"
        fontSize="1rem"
        pb="l"
        letterSpacing="0.4px"
        onClick={showDetailsHandler}
        cursor="pointer"
        title={active ? t('sellOffersComponent:hideDetails') : t('sellOffersComponent:showDetails')}
      >
        {active ? `${t('sellOffersComponent:hideDetails')} - ` : `${t('sellOffersComponent:showDetails')} +`}
      </Text>
    </Box>
  )
}

export default Offer
