import React, { FC } from 'react'
import { Box } from '@chakra-ui/react'
import { theme } from 'src/themes'

interface BoxProps {
  display?: Partial<Record<string, any>>,
  children?: any
}

const FiltersListBox: FC<BoxProps> = ({ display, children }) => {
  return (
    <Box
      borderRadius={{ base: 0, md: 10 }}
      bg="baseWhite"
      p="l"
      mt="xxs"
      borderWidth={{ base: 0, md: 1 }}
      borderColor={theme.colors.borders.grey}
      boxShadow={theme.shadows.cards}
      textAlign="left"
      display={display}
      whiteSpace={{ base: 'nowrap', md: 'normal' }}
    >
      {children}
    </Box>
  )
}

export default FiltersListBox
