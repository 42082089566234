import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FilterSwitch as CommonFilterSwitch } from 'src/components/common'
import { filterIsSelected } from 'src/redux/sell-offer/selectors'

interface FilterProps {
  groupKey: string
  title: string
  label: string
  onChange?: any
}

const FilterSwitch: FC<FilterProps> = ({ groupKey, title, label, onChange }) => {
  const isSelected = useSelector(filterIsSelected({ label, name: title, value: '1' }, groupKey))
  const [checked, setChecked] = useState(isSelected);

  useEffect(() => {
    return setChecked(isSelected)
  }, [isSelected])

  return <CommonFilterSwitch
    label={label}
    title={title}
    isSelected={checked}
    onChange={onChange}
  />

}

export default FilterSwitch
