import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { toggleOrder } from 'src/redux/sell-offer'

const SortsModal = () => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const orders = useSelector((state: Store.RootState) => state.sell.orders)
  const changeOrderHandler = value => dispatch(toggleOrder(value))

  return (
    <>
      <Center w="100%" h="14" as="button" onClick={onOpen}>
        {t('sellActionbarContainer:sortBy')} <ChevronDownIcon />
      </Center>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton mt="1" />
          <DrawerHeader borderBottomWidth="1px">{t('sellMobileFiltersModalContainer:doSort')}</DrawerHeader>
          <DrawerBody>
            {orders.map(sort => (
              <Button
                borderRadius="0"
                w="100%"
                bg="baseWhite"
                mt="xxs"
                key={sort.value}
                onClick={() => changeOrderHandler(sort.value)}
                fontWeight="normal"
              >
                {sort.label || sort.name}
              </Button>
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SortsModal
