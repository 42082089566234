import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { FilterTagCheckbox as CommonFilterTagCheckbox } from 'src/components/common'
import { filterIsSelected, filterIsHidden } from 'src/redux/sell-offer/selectors'

interface FilterProps {
  item: Filter.Tag
  groupKey: string
  onClick?(): any
}

const FilterTagCheckbox: FC<FilterProps> = ({ item, groupKey, onClick }) => {
  const isHidden = useSelector(filterIsHidden(item, groupKey))

  if (isHidden) {
    return null
  }

  const isSelected = useSelector(filterIsSelected(item, groupKey))

  return <CommonFilterTagCheckbox
    isChecked={isSelected}
    onChange={onClick}
    label={item.name}
  />
}

export default FilterTagCheckbox
