import React, { FC } from 'react'
import { Box, Text, Stack } from '@chakra-ui/react'

interface Props {
  items: Array<ItemProps>
  description?: string
}

interface ItemProps {
  name: string
  value: string
}

const TextItem: FC<ItemProps> = ({ name, value }) => (
  <Box display="block">
    <Text
      color="baseDark"
      fontFamily="Montserrat-SemiBold"
      fontSize="0.875rem"
      letterSpacing="0.2px"
      lineHeight="18px"
      display="inline"
    >
      {name}
    </Text>
    <Text
      color="baseNeutral.700"
      fontFamily="Montserrat-Medium"
      fontSize="1rem"
      letterSpacing="0.2px"
      lineHeight="20.8px"
      display="inline"
    >
      : {value}
    </Text>
  </Box>
)

const OfferDetails: FC<Props> = ({ items, description }) => (
  <Stack
    textAlign="left"
    position="absolute"
    bottom="0"
    left="0"
    right="0"
    display="block"
    bg="baseWhite"
    p="l"
    overflow="hidden"
  >
    {items.map(item => (
      <TextItem key={item.name} name={item.name} value={item.value} />
    ))}
    {description && (
      <Text
        color="baseNeutral.700"
        fontFamily="Montserrat-Medium"
        fontSize="0.75rem"
        letterSpacing="0.4px"
        marginTop="m"
      >
        {description}
      </Text>
    )}
  </Stack>
)

export default OfferDetails
