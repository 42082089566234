import React from 'react'
import { Accordion } from '@chakra-ui/react'
import { FiltersListItemContent } from '.'

const FiltersListItem = ({ items }) => {
  return (
    <Accordion fontFamily="Montserrat-Regular" allowToggle={true}>
      {Object.keys(items).map(idx => (
        <FiltersListItemContent key={idx} filter={items[idx]} />
      ))}
    </Accordion>
  )
}

export default FiltersListItem
