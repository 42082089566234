import React from 'react'
import { useSelector } from 'react-redux'
import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { toggleFilter } from 'src/redux/sell-offer/actions'
import { theme } from 'src/themes'
import {
  FiltersListBox,
  FiltersListGroups,
  FilterTag,
} from '.'

const FiltersList = () => {
  const { t } = useTranslation()

  const suggestedFilters = useSelector((state: Store.RootState) => state.sell.suggestedFilters)

  return (
    <>
      <FiltersListBox display={{ base: 'block', md: 'none' }}>
        <Text fontFamily="Montserrat-SemiBold" fontWeight="bold" fontSize={theme.space.s} color="baseNeutral.700" pb="4">
          {t('offersListContainer:suggestedFilters')}
        </Text>
        {suggestedFilters.map((filterGroup: any) =>
          filterGroup.items.map((item: Filter.Tag) =>
            <FilterTag
              item={item}
              groupKey={filterGroup.key}
              key={`suggested-${item.name}-${item.value}`}
              onClick={() => toggleFilter(item.value, filterGroup.key)}
            />
          )
        )}
      </FiltersListBox>

      <FiltersListGroups />
    </>
  )
}

export default FiltersList
