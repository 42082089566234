import React, { FC } from 'react'
import { Box, Text, Heading } from '@chakra-ui/react'
import { theme } from 'src/themes'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { ListWithIcons, CardLink } from 'src/uikit'

interface LocalProps {
  item: OfferGlobal.AutoPromoItem
}

const AutoPromo: FC<LocalProps> = ({ item }) => {
  return (
    <CardLink href={item.link || ''} title={item.linkText || 'Scopri di più'}>
      <Box
        bg={item.layout === 'outline' ? 'baseWhite' : 'primaryGreen.800'}
        bgImage={item.image ? `url(${item.image})` : ''}
        bgPosition={item.image ? '95% 95%' : ''}
        bgRepeat={item.image ? 'no-repeat' : ''}
        bgSize={item.image ? '20%' : ''}
        p="l"
        h="370px"
        borderRadius={10}
        overflow="hidden"
        borderColor={item.layout === 'outline' ? theme.colors.borders.grey : 'primaryGreen.800'}
        borderWidth={1}
        boxShadow={theme.shadows.cards}
        textAlign="left"
        color={item.layout === 'outline' ? 'baseDark' : 'baseWhite'}
        position="relative"
      >
        <Heading fontSize="1.75rem">{item.title}</Heading>
        {item.text &&
          <Text marginTop="s" fontFamily="Montserrat-Medium" fontSize="1.125rem" pb="s">
            {item.text}
          </Text>
        }
        {item.listItems && <ListWithIcons items={item.listItems} color="baseDark" />}
        {item.link && item.linkText &&
          <Text title={item.linkText} fontSize="m" color={item.layout === 'outline' ? 'baseDark' : 'baseWhite'}>
            {item.linkText} <ArrowForwardIcon />
          </Text>}
      </Box>
    </CardLink >
  )
}

export default AutoPromo
