import React, { FC } from 'react'
import {
  Box,
  SimpleGrid,
} from '@chakra-ui/react'

interface WrapperProps {
  items: [{ icon?: string }]
  isMobile?: boolean,
  listedRows?: boolean,
  children?: any
}

const FiltersListItemsWrapper: FC<WrapperProps> = ({ items, isMobile, listedRows, children }) => {
  if (items.some(item => item.icon !== undefined) && !isMobile) {
    return listedRows
      ?
      <SimpleGrid minChildWidth="100%" spacing="8px">{children}</SimpleGrid>
      :
      <SimpleGrid minChildWidth="5.625rem" spacing="8px">{children}</SimpleGrid>;
  }

  return <Box overflowX={{ base: 'scroll', md: null }} paddingBottom={{ base: '0.5rem', md: null }}>{children}</Box>;
}

export default FiltersListItemsWrapper;
