import React from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@chakra-ui/react'
import { toggleFilter } from 'src/redux/sell-offer/actions'
import { FilterTag } from '.'

const FiltersListItemNoChildren = ({ items, groupKey }) => {
  const dispatch = useDispatch()
  const toggleFilterHandler = filter => dispatch(toggleFilter(filter.value, groupKey))

  return <>
    <Box
      flex="1"
      textAlign="left"
      textTransform="uppercase"
      fontFamily="Montserrat-SemiBold"
      display={{ base: 'block', md: 'none' }}
    >
      Tag
    </Box>
    {
      items.map(item => (
        <FilterTag
          item={item}
          groupKey={groupKey}
          key={`filters-ref-${item.name}-${item.value}`}
          onClick={() => toggleFilterHandler(item)}
        />
      ))
    }
  </>
}

export default FiltersListItemNoChildren
