import React from 'react'
import { useSelector } from 'react-redux';
import { FiltersListGroup } from '.'

const FiltersListGroups = () => {
  const filters = useSelector((state: Store.RootState) => state.sell.filters)

  const filtersGrouped = filters.reduce((r, a) => {
    r[a.group] = r[a.group] || [];
    r[a.group].push(a);
    return r;
  }, Object.create(null));

  return (
    <>
      {
        Object.keys(filtersGrouped).map(filterGroup =>
          <FiltersListGroup
            key={filterGroup}
            groupLabel={filterGroup}
            group={filtersGrouped[filterGroup]}
          />
        )
      }
    </>
  )
}

export default FiltersListGroups
