import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Select,
  SimpleGrid,
  Slide,
  useBreakpointValue
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { ArrowLeftIcon, ArrowRightIcon } from 'src/icons'
import { toggleFilterMenu, toggleOrder } from 'src/redux/sell-offer/actions'
import { FiltersList } from "."

const ActionBar = ({ offersCount = null }) => {
  const visibleOnMobile = useBreakpointValue({ base: true, md: false })
  const orders = useSelector((state: Store.RootState) => state.sell.orders)
  const offers = useSelector((state: Store.RootState) => state.sell.filteredOffers)
  const filterMenuIsOpen = useSelector((state: Store.RootState) => state.sell.filterMenuIsOpen)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const toggleFilterMenuHandler = () => dispatch(toggleFilterMenu())
  const changeOrderHandler = ({ target: { value } }) => dispatch(toggleOrder(value))

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 4 }} pr="l">
        <Box textAlign="left" display={{ base: 'none', md: 'flex' }}>
          <Button
            onClick={toggleFilterMenuHandler}
            leftIcon={filterMenuIsOpen ? <ArrowLeftIcon /> : <ArrowRightIcon />}
            color="baseDark"
            fontFamily="Montserrat-Medium"
            variant="link"
            fontSize="1rem"
            fontWeight="normal"
          >
            {filterMenuIsOpen ? t('sellActionbarContainer:hideFilters') : t('sellActionbarContainer:showFilters')}
          </Button>
        </Box>
        <Box textAlign="left" fontFamily="Montserrat-SemiBold" fontSize="1.375rem" pt="2" pl="3">
          {t('sellActionbarContainer:nOffersFound', { count: offersCount || offers.results })}
        </Box>
        <Box display={{ base: 'none', md: 'flex' }} />
        <Box textAlign="right" fontFamily="Montserrat-SemiBold" fontSize="1rem" display={{ base: 'none', md: 'block' }}>
          {t('sellActionbarContainer:sortBy')}
          <Select
            variant="flushed"
            display="inline-block"
            width="initial"
            ml={4}
            fontFamily="Montserrat-Regular"
            onChange={changeOrderHandler}
          >
            {orders.map(sort => (
              <option key={sort.value} value={sort.value}>
                {sort.label || sort.name}
              </option>
            ))}
          </Select>
        </Box>
      </SimpleGrid>
      {!visibleOnMobile &&
        <Slide
          className="offers-filters-desktop-container"
          in={filterMenuIsOpen}
          direction="left"
          unmountOnExit={true}
        >
          <Box>
            <FiltersList />
          </Box>
        </Slide>
      }
    </>
  )
}

export default ActionBar
