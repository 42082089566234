import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { FilterTagImage as CommonFilterTagImage } from 'src/components/common'
import { filterIsSelected, filterIsHidden } from 'src/redux/sell-offer/selectors'

interface FilterProps {
  item: Filter.TagImage
  groupKey: string
  onClick?(): any
  isMobile?: boolean
}

const FilterTagImage: FC<FilterProps> = ({ item, groupKey, onClick, isMobile }) => {
  const isHidden = useSelector(filterIsHidden(item, groupKey))

  if (isHidden) {
    return null
  }

  const isSelected = useSelector(filterIsSelected(item, groupKey))

  return <CommonFilterTagImage
    isSelected={isSelected}
    label={item.name}
    icon={item.icon}
    iconAlt={item.icon_alt || ''}
    isMobile={isMobile}
    onClick={onClick}
  />
}

export default FilterTagImage
